<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="investors"
                :items-per-page="-1"
                :search="searchInvestor"
                :loading="loadingData"
                :custom-filter="customSearch"
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }"
            >
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-md-h6">Asignación de Proyectos a Inversionistas</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-investor"
                                label="Buscar.."
                                name="findinvestor"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchInvestor"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown"
                                  v-model="dialog"
                                  max-width="500px"
                                  persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="blue darken-1"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="editedItem.user"
                                                        :items="usersList"
                                                        :item-text="getItemText"
                                                        item-value="id"
                                                        item-color="primary"
                                                        return-object
                                                        :rules="[v => !!v || 'Una Inversionista es obligatorio']"
                                                        required
                                                        clearable
                                                        label="Usuario Inversionista">
                                                        <template v-slot:item="slotProps">
                                                            <div class="fill-height container--fluid justify-start">
                                                                <v-row align="center" justify="start">
                                                                    <v-col cols="5">
                                                                        <v-avatar
                                                                            class="profile"
                                                                            color="transparent"
                                                                            size="100">
                                                                            <v-icon x-large>mdi-account</v-icon>
                                                                        </v-avatar>
                                                                    </v-col>
                                                                    <v-col cols="7">
                                                                        <span class="text-caption font-weight-medium"
                                                                              style="width: 100%">
                                                                            {{ slotProps.item.name }} {{ slotProps.item.last_name }}
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <!--<v-col cols="12">
                                                    <v-text-field
                                                        v-model="editedItem.investment_type"
                                                        label="Tipo de Inversión"
                                                        :rules="nameRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>-->
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="editedItem.projects"
                                                        :items="projectsList"
                                                        :rules="[v => !!v || 'Un proyecto es obligatorio']"
                                                        item-text="name"
                                                        item-value="id"
                                                        return-object
                                                        label="Proyectos"
                                                        multiple
                                                        item-color="primary"
                                                        chips
                                                        hint="Seleccione los proyectos asociados"
                                                        persistent-hint>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary"
                                               text
                                               @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid"
                                               color="primary"
                                               text
                                               @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" width="auto">
                            <v-card>
                                <v-card-title class="text-subtitle-1">¿Está seguro que desea eliminar esta asignación?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <!--<template #item.full_name="{ item }">
                    {{ item.user.name }} {{ item.user.last_name }}
                </template>-->
                <template v-slot:item.full_name="{ item }">
                    {{ item.user.name }} {{ item.user.last_name }}
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                        v-model="item.enabled"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay Asignaciones en el listado</span>
                    <br/>
                    <v-btn color="primary" @click="getInvestors">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
import investorService from '@/providers/InvestorService';
import projectsService from '@/providers/ProjectsService';
import userService from '@/providers/UsersService';

export default {
    name: "InvestorsModuleComponent",
    data: () => ({
        snackBar: false,
        snackText: '',
        valid: true,
        selectUser: null,
        archived: false,
        loadingData: false,
        searchInvestor: '',
        dialog: false,
        dialogDelete: false,
        investors: [],
        usersList: [],
        projectsList: [],
        listUsers: [],
        listInvestor: [],
        headers: [
            {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
            {text: "Nombre del Inversionista", value: "full_name"},
            {text: "Cantidad de Proyectos", value: "projects_count", align: 'center', filterable: false},
            // {text: 'Tipo de Inversion', align: 'start', sortable: false, value: 'investment_type'},
            {text: 'Acciones', value: 'actions', width: 100, sortable: false},
        ],
        editedIndex: -1,
        editedItem: {
            id: 0,
            investment_type: '',
            user_id: 0,
            projects_count: 0,
            user: null,
            project: null
        },
        defaultItem: {
            id: 0,
            investment_type: '',
            user_id: 0,
            projects_count: 0,
            user: null,
            project: null
        },
        nameRules: [
            v => !!v || 'El Campo es obligatorio'
            // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter.',
        ],
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Asignación' : 'Editar Asignación';
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    methods: {
        customSearch(value, search, item) {
            return item.user.name.toLowerCase().includes(search.toLowerCase());
        },
        getItemText(item) {
            return `${item.name} ${item.last_name}`;
        },
        getInvestors() {
            this.loadingData = true;
            // Lista de Usuarios
            userService.getViewListInvestors().then(record => {
                this.listInvestor = record.value;
            });
            investorService.listUsers().then(record => {
                this.listUsers = record.value;
            });
            // Lista de Inversionistas
            investorService.getViewList().then(record => {
                this.investors = record.value;
            });
            // Lista de Proyectos
            const archived = Number(this.archived);
            projectsService.getViewList(archived).then(record => {
                this.projectsList = record.value;
                this.loadingData = false;
            });
        },
        newItem() {
            this.valid = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.usersList = this.listUsers;
        },
        editItem(item) {
            this.usersList = this.listInvestor;
            this.editedIndex = this.investors.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.investors.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            investorService.deleteRecord(this.editedItem.id).then(
                response => {
                    if (response.success) {
                        this.investors.splice(this.editedIndex, 1);
                        this.snackText = 'Asignación eliminada con éxito.';
                        this.getInvestors();
                    } else {
                        this.snackText = 'Un error impidió eliminar la asignación';
                    }
                }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error impidió eliminar la asignación";
                    this.dialogError = true;
                }).finally(() => {
                    this.closeDelete();
                    this.snackBar = true;
                });
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                // console.log('editedItem: ', this.editedItem);
                if (this.editedIndex > -1) {
                    // Actualizar el registro por el metodo PUT
                    Object.assign(this.investors[this.editedIndex], this.editedItem);
                    investorService.updateRecord(this.editedItem).then(
                        response => {
                            if (response.success) {
                                this.getInvestors();
                            }
                        }
                    );
                } else {
                    // Agrega el registro por el metodo POST
                    this.investors.push(this.editedItem);
                    investorService.addRecord(this.editedItem).then(
                        response => {
                            if (response.success) {
                                this.getInvestors();
                            }
                        }
                    )
                }
                this.close();
                this.snackText = 'Registro guardado con éxito.';
                this.snackBar = true;
            }
        }
    },
    mounted() {
        this.investors = [];
        this.getInvestors();
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}
</style>